import React from "react";
import posthog from "posthog-js";

import Layout from "../components/layout";

export default function Brands() {
  posthog.capture("$brands");
  return (
    <Layout>
      <main
        id="content"
        role="main"
        className="main sv-landing full-page-content"
      >
        <section className="overflow-hidden">
          <h2 className="md-h2 caps pl4 mt4">
            <div>Brands</div>
          </h2>
          <div className="mb4 pl4 pr4 mt4">
            <p>
              We carry a wide selection of designer frames, including the
              following featured brands:
            </p>
            <ul>
              <li className="line-height-3">Aspire</li>
              <li className="line-height-3">Bottega Veneta</li>
              <li className="line-height-3">Burberry</li>
              <li className="line-height-3">Eco</li>
              <li className="line-height-3">Gucci</li>
              <li className="line-height-3">Marc Jacobs</li>
              <li className="line-height-3">Modo</li>
              <li className="line-height-3">Montblanc</li>
              <li className="line-height-3">Oakley</li>
              <li className="line-height-3">Polaroid</li>
              <li className="line-height-3">Prada</li>
              <li className="line-height-3">ProDesign</li>
              <li className="line-height-3">Ray Ban</li>
              <li className="line-height-3">WOOW</li>
              <li className="line-height-3">Yves Saint Laurent</li>
            </ul>
          </div>
        </section>
      </main>
    </Layout>
  );
}
